import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../components/Seo"
import PageHeader from "../components/PageHeader"
import Spacer from "../components/Spacer"
import FlexibleContent from "../components/FlexibleContent"

export default function Privacy() {
  const {
    wpPage: {
      pageHeaderFields: { headerContent },
      seo,
      featuredImage,
      flexibleGeneralContentFields: { content: pageContent },
    },
  } = useStaticQuery(graphql`
    query {
      wpPage(title: { eq: "Privacy Policy & Cookies" }) {
        pageHeaderFields {
          headerContent
        }
        seo {
          metaDesc
          title
          opengraphImage {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        flexibleGeneralContentFields {
          ...FlexibleContent
        }
      }
    }
  `)

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} />
      <Spacer size={32} when={{ md: 48 }} />
      <FlexibleContent content={pageContent} />
      <Spacer size={32} when={{ md: 48 }} />
    </>
  )
}
